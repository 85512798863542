













































import Component from 'vue-class-component'
import Vue from 'vue'
import ModalDeleteCountryInventory from './ModalDeleteCountryInventory.vue'
import ModalEditCountryInventory from './ModalEditCountryInventory.vue'
import { fetchCountryInventoryMappingsSchema, getAllCountryInventoryMappings } from './api'

@Component({
    components: { ModalDeleteCountryInventory, ModalEditCountryInventory }
})
class CountryInventoryMappingList extends Vue {
    // schema
    inventorySchema: any = null
    // values to pass to dropdowns
    dropdownValues = []
    // shown items
    items: CountryInventoryMapping[] = []
    // noty
    private $noty!: NotyObj

    private async created() {
        this.inventorySchema = await fetchCountryInventoryMappingsSchema(this.$noty)
        this.dropdownValues = this.inventorySchema.properties.classification.enum
        await this.updateData()
    }

    private async updateData() {
        const countryInventoryMappings = await getAllCountryInventoryMappings(this.$noty)
        this.items = countryInventoryMappings.map((item: CountryInventoryMapping) => ({
            ...item,
            classification: item.classification ? item.classification : 'UNSET'
        }))
    }
}

export default CountryInventoryMappingList
