<template>
    <v-layout column>
        <v-card class="mb-4">
            <v-card-title v-t="'countryInventory.headline'" class="primary--text" />
        </v-card>
        <country-inventory-mapping-list />
    </v-layout>
</template>

<script>
import CountryInventoryMappingList from '@/components/country-inventory-mapping/CountryInventoryMappingList'

export default {
    name: 'Products',
    components: {
        CountryInventoryMappingList
    }
}
</script>
