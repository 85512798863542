























import Component from 'vue-class-component'
import Vue from 'vue'
import { mdiDelete } from '@mdi/js'
import { Prop } from 'vue-property-decorator'
import { deleteCountryInventoryMappingWithId } from '@/components/country-inventory-mapping/api'

@Component
class ModalDeleteCountryInventory extends Vue {
    @Prop({ type: Number, required: true })
    readonly id!: number

    svgDelete = mdiDelete
    isOpen = false

    // noty
    private $noty!: NotyObj

    private async onDeletebutton() {
        await deleteCountryInventoryMappingWithId(this.id, this.$noty)
        this.isOpen = false
        this.$emit('on-deleted')
    }
}

export default ModalDeleteCountryInventory
