import i18n from '@/i18n'
import fetchWithAuthCheck from '@/components/utils'

const headers = new Headers({ 'content-type': 'application/hal+json' })

const fetchCountryInventoryMappingsSchema = async (noty: NotyObj) => {
    try {
        const schemaResponse = await fetchWithAuthCheck('/api/profile/countryInventoryMappings', {
            headers: { Accept: 'application/schema+json' }
        })
        return schemaResponse.json()
    } catch (e) {
        noty.error('Error on loading schema')
        return Promise.reject()
    }
}

const fetchCountryRegulationSchema = async (noty: NotyObj) => {
    try {
        const schemaResponse = await fetchWithAuthCheck('/api/profile/countryRegulations', {
            headers: { Accept: 'application/schema+json' }
        })
        return await schemaResponse.json()
    } catch (e) {
        noty.error(e)
    }
}

const createCountryInventoryMapping = async (payload: CountryInventoryPayload, noty: NotyObj) => {
    try {
        const fetchOptions = {
            headers,
            method: 'POST',
            body: JSON.stringify(payload)
        }
        const response = await fetchWithAuthCheck('/api/countryInventoryMappings', fetchOptions)
        if (response.ok) {
            noty.success(i18n.t('countryInventory.messages.successCreate') as string, { timeout: 2000 })
        } else {
            noty.error(i18n.t('countryInventory.messages.errorCreate') + response.statusText)
        }
        return response.json()
    } catch (e) {
        noty.error(i18n.t('countryInventory.messages.errorCreate') + e)
        return Promise.reject()
    }
}

const updateCountryInventoryMapping = async (payload: CountryInventoryPayload, noty: NotyObj) => {
    try {
        const fetchOptions = {
            headers,
            method: 'POST',
            body: JSON.stringify(payload)
        }
        const response = await fetchWithAuthCheck('/api/countryInventoryMappings', fetchOptions)
        if (response.ok) {
            noty.success(i18n.t('countryInventory.messages.successUpdate') as string, { timeout: 2000 })
        } else {
            noty.error(i18n.t('countryInventory.messages.errorUpdate') + response.statusText)
        }
        return response.json()
    } catch (e) {
        noty.error(i18n.t('countryInventory.messages.errorUpdate') + e)
        return Promise.reject()
    }
}

const deleteCountryInventoryMappingWithId = async (id: number, noty: NotyObj) => {
    try {
        const fetchOptions = {
            headers,
            method: 'DELETE'
        }
        const response = await fetchWithAuthCheck(`/api/countryInventoryMappings/${id}`, fetchOptions)
        if (response?.ok) {
            noty.success(i18n.t('countryInventory.messages.successDelete') as string, { timeout: 2000 })
        } else {
            noty.error(i18n.t('countryInventory.messages.errorDelete') + response?.statusText)
        }
        return Promise.resolve()
    } catch (e) {
        noty.error(i18n.t('countryInventory.messages.errorDelete') + e)
        return Promise.reject()
    }
}

const getAllCountryInventoryMappings = async (noty: NotyObj) => {
    try {
        const inventoryResponse = await fetchWithAuthCheck('/api/countryInventoryMappings?size=1000')
        const countryInventoryMappingsResponse: DuiResponse<
            'countryInventoryMappings',
            CountryInventoryMapping
        > = await inventoryResponse.json()
        return countryInventoryMappingsResponse?._embedded?.countryInventoryMappings
    } catch (e) {
        noty.error(e)
        return []
    }
}

export {
    fetchCountryRegulationSchema,
    fetchCountryInventoryMappingsSchema,
    createCountryInventoryMapping,
    updateCountryInventoryMapping,
    deleteCountryInventoryMappingWithId,
    getAllCountryInventoryMappings
}
