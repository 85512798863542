export default [
    'REACh',
    'TSCA',
    'DSL/NDSL',
    'ENCS',
    'AIIC',
    'ECL',
    'PICCS',
    'IECSC',
    'TCSI',
    'NZIoC',
    'Vietnam',
    'Turkey',
    'EAEU Reach',
    'Schweiz',
    'UK-REACH'
]
