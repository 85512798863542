

























































import Component from 'vue-class-component'
import Vue from 'vue'
import { mdiPencil, mdiPlus } from '@mdi/js'
import { Prop, Watch } from 'vue-property-decorator'
import countryTargets from './countryTargets'
import {
    createCountryInventoryMapping,
    updateCountryInventoryMapping
} from '@/components/country-inventory-mapping/api'

@Component
class ModalEditCountryInventory extends Vue {
    @Prop({ type: (Object as () => CountryInventoryMapping) || null, default: () => null })
    readonly item!: CountryInventoryMapping | null

    @Prop({ type: Array, default: () => [] })
    readonly dropdownValues!: string[]

    currentTarget: string = ''
    currentPhrase: string = ''
    currentRating: CountryInventoryRating = null
    countryInventoryValues = countryTargets

    svgPencil = mdiPencil
    svgAdd = mdiPlus
    isOpen = false

    // noty
    private $noty!: NotyObj

    get dropdownItems() {
        return this.dropdownValues.map(el => ({
            value: el,
            text: this.$t(`countryInventory.values.${el}`)
        }))
    }

    get isValid() {
        return this.currentTarget && this.currentPhrase && this.currentRating
    }

    @Watch('isOpen')
    private setOrigin() {
        if (this.item) {
            const { mapping, sourceColumn, classification } = this.item
            this.currentTarget = sourceColumn
            this.currentPhrase = mapping
            this.currentRating = classification
        } else {
            this.$nextTick(() => {
                const form = this.$refs.form as Vue & { reset: () => boolean }
                form.reset()
            })
        }
    }

    private async onSaveButton() {
        const payload: CountryInventoryPayload = {
            sourceColumn: this.currentTarget,
            mapping: this.currentPhrase,
            classification: this.currentRating
        }
        if (this.item) {
            payload.id = this.item.id
            await updateCountryInventoryMapping(payload, this.$noty)
        } else {
            await createCountryInventoryMapping(payload, this.$noty)
        }

        this.isOpen = false
        this.$emit('on-saved')
    }
}

export default ModalEditCountryInventory
